/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { shape, string } from 'prop-types'

import Seo from '~/components/seo'
import Layout from '~/layout/Layout'
import Sections from '~/content/Sections'

const DISPLAY_NAME = 'Page'

const PROP_TYPES = {
  data: shape({
    page: shape({
      metaTitle: string,
      metaDescription: string,
    }).isRequired,
  }).isRequired,
}

const Component = ({ data: { page } }) => {
  const { metaTitle, metaDescription, sections, textColorScheme } = page
  return (
    <Layout textColorScheme={textColorScheme}>
      <Seo
        title={metaTitle}
        description={metaDescription}
      />
      <Sections sections={sections} />
    </Layout>
  )
}

export const query = graphql`
  query ($path: String) {
    page: contentfulPage(path: { eq: $path }) {
      ...Page
    }
  }
`

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
