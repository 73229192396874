import React from 'react'
import loadable from '@loadable/component'

import convertTypename from '~/utilities/convertTypename'

const SECTIONS = {
  CardLargeSection: loadable(() => import('~/sections/CardLargeSection')),
  CardMediumSection: loadable(() => import('~/sections/CardMediumSection')),
  CenteredTextHeroSection: loadable(() => import('~/sections/CenteredTextHeroSection')),
  CopyAndCtaSection: loadable(() => import('~/sections/CopyAndCtaSection')),
  CtaSection: loadable(() => import('~/sections/CtaSection')),
  EmailSubscribeHeroSection: loadable(() => import('~/sections/EmailSubscribeHeroSection')),
  EmailSubscribeSection: loadable(() => import('~/sections/EmailSubscribeSection')),
  FaqSection: loadable(() => import('~/sections/FaqSection')),
  FinePrintSection: loadable(() => import('~/sections/FinePrintSection')),
  GenericHeroSection: loadable(() => import('~/sections/GenericHeroSection')),
  ImageCopySection: loadable(() => import('~/sections/ImageCopySection')),
  PlanComparisonSection: loadable(() => import('~/sections/PlanComparisonSection')),
  PricingHeroSection: loadable(() => import('~/sections/PricingHeroSection')),
  PricingSliderSection: loadable(() => import('~/sections/PricingSliderSection')),
  TextMediaOverlapHeroSection: loadable(() => import('~/sections/TextMediaOverlapHeroSection')),
}

const DISPLAY_NAME = 'Sections'

const Component = ({ sections }) => sections.map((
  {
    __typename: typename,
    id,
    ...props
  },
  index,
) => {
  const sectionType = convertTypename(typename)
  const Section = SECTIONS[sectionType]
  if (!Section) {
    // eslint-disable-next-line no-console
    console.warn(`No section matching \`${sectionType}\``)
    return null
  }
  const key = `${id}-${index}`
  return (
    <Section
      key={key}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
})

Component.displayName = DISPLAY_NAME

export default Component
